<template>
  <van-form
    :show-error-message="false"
    label-width="23vw"
    class="van-form__text-color"
  >
    <van-field
      :value="info.name"
      disabled
      label="隐患名称"
      type="textarea"
      :rows="1"
      :autosize="true"
    />
    <van-field :value="info.poiName" disabled label="隐患部位" />
    <van-field
      :value="info.troubleLevelName"
      disabled
      label="隐患级别"
      name="troubleLevel"
    />
    <van-field
      :value="info.categoryName"
      disabled
      label="隐患类别"
      name="categoryId"
    />
    <van-field
      :value="info.dangerTypeName || '--'"
      disabled
      label="危害因素类型"
      name="dangerType"
    />
    <van-field
      v-if="info.contractorName"
      :value="info.contractorName"
      disabled
      label="承包商"
    />
    <van-field :value="info.planName" disabled label="所属计划" name="planId" />
    <van-field :value="info.departmentName" disabled label="所属部门" />
    <van-field
      v-if="hasTroubleType"
      :value="info.typeName"
      disabled
      label="隐患分类"
    />
    <van-field :value="info.discoveryName" disabled label="发现方式" />
    <van-field
      :value="info.description"
      disabled
      label="备注"
      type="textarea"
      :rows="1"
      :autosize="true"
    />
    <van-field
      :value="info.gist"
      disabled
      label="检查依据"
      name="gist"
      type="textarea"
      :rows="1"
      :autosize="true"
    />
    <van-field
      :value="info.suggest"
      disabled
      label="整改建议"
      :rows="1"
      :autosize="true"
      type="textarea"
    />
    <van-field label="现场照片" name="uploader">
      <template #input>
        <van-uploader
          v-model="fileList"
          :deletable="false"
          disabled
          class="trouble-info__uploader"
        />
      </template>
    </van-field>
    <van-field
      :value="info.troubleSourceName"
      disabled
      label="隐患来源"
      name="gist"
      type="textarea"
      :rows="1"
      :autosize="true"
    />
  </van-form>
</template>

<script>
import { parseEmptyObj } from "@/utils";
import { getModuleOptionItems } from "@/api/psm/trouble";

// 隐患分类字典表id
const TROUBLE_DIC_ID = "10400101001";
export default {
  props: ["id"],
  inject: {
    detailsComponents: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      optionItems: []
    };
  },
  computed: {
    fileList() {
      return this.detailsComponents.fileList || [];
    },
    info() {
      return { ...parseEmptyObj({ ...this.detailsComponents.troubleInfo }) };
    },
    hasTroubleType() {
      const optionItem =
        this.optionItems.find(i => i.id === TROUBLE_DIC_ID) || {};
      return optionItem.itemValue === "1";
    }
  },
  async created() {
    this.optionItems = await getModuleOptionItems();
  },
  methods: {}
};
</script>
<style>
.trouble-info__uploader .van-uploader__wrapper--disabled {
  opacity: 1;
}
</style>
